import {Box, Button, ButtonGroup, Card, CardActions, CardHeader, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';

import axios from 'axios';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ArticleIcon from '@mui/icons-material/Article';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';

interface DevToArticleReactionInfo {
  category: string;
  count: number;
}

interface DevToArticleReactionsInfo {
  article_reaction_counts: Array<DevToArticleReactionInfo>;
}

interface DevToArticleInfo {
  id: number;
  title: string;
  comments_count: number;
  reading_time_minutes: number;
  readable_publish_date: string;
}

interface DevToArticleCounters {
  savings_count: number;
  likes_count: number;
}

function DevToArticle({ path, medium, hackernoon }: { path: string, medium?: string, hackernoon?: string }) {
  const [info, setInfo] = useState<DevToArticleInfo>({
    id: 0,
    title: 'Loading...',
    readable_publish_date: 'Loading...',
    comments_count: 0,
    reading_time_minutes: 0,
  });
  const [counters, setCounters] = useState<DevToArticleCounters>({
    savings_count: 0,
    likes_count: 0,
  });

  useEffect(() => {
    axios
      .get(`https://dev.to/api/articles/${path}`)
      .then(({ data }: { data: DevToArticleInfo }) => {
        setInfo({
          id: data.id,
          title: data.title,
          readable_publish_date: data.readable_publish_date,
          comments_count: data.comments_count,
          reading_time_minutes: data.reading_time_minutes,
        });
        return data;
      })
      .then(( data ) => {
        axios
          .get(`https://dev.to/reactions?article_id=${data.id}`)
          .then(({data}: {data: DevToArticleReactionsInfo}) => {
            const reading_list_reaction = data
              .article_reaction_counts
              .find(item => item.category === 'readinglist');
            const reactions_to_cum = [
              'like',
              'unicorn',
              'exploding_head',
              'raised_hands',
              'fire',
            ];
            const likes_count = data
              .article_reaction_counts
              .filter(item => reactions_to_cum.includes(item.category))
              .reduce((sum, current) => sum + current.count, 0);

            if (reading_list_reaction !== undefined) {
              setCounters({
                savings_count: reading_list_reaction.count,
                likes_count: likes_count,
              });
            }
          })
          .catch(() => {});
      })
      .catch(() => {});
  }, [path]);

  return (
    <Card sx={{ marginBottom: '16px' }}>
      <CardHeader
        sx={{ paddingBottom: '0px' }}
        title={
          <Typography variant="body1" gutterBottom>
            {info.title}
          </Typography>
        }
        action={
          <ButtonGroup sx={{ marginLeft: 'auto', marginRight: '12px' }}>
            {counters.likes_count > 0 ? (
              <Button
                disabled
                variant="text"
                color="inherit"
                size="large"
                startIcon={<FavoriteBorderIcon />}
                sx={{
                  paddingRight: '0px',
                  paddingLeft: '0px',
                  '& .MuiButton-startIcon': {
                    margin: '4px',
                  },
                  ':disabled': {
                    color: 'text.secondary',
                  },
                }}
              >
                {counters.likes_count}
              </Button>
            ) : null}

            {info.comments_count > 0 ? (
              <Button
                disabled
                variant="text"
                color="inherit"
                size="large"
                startIcon={<ForumOutlinedIcon />}
                sx={{
                  paddingRight: '0px',
                  paddingLeft: '0px',
                  '& .MuiButton-startIcon': {
                    margin: '6px',
                  },
                  ':disabled': {
                    color: 'text.secondary',
                  },
                }}
              >
                {info.comments_count.toString()}
              </Button>
            ) : null}

            {counters.savings_count > 0 ? (
              <Button
                disabled
                variant="text"
                color="inherit"
                size="large"
                startIcon={<BookmarkBorderIcon />}
                sx={{
                  paddingRight: '0px',
                  paddingLeft: '0px',
                  '& .MuiButton-startIcon': {
                    margin: '4px',
                  },
                  ':disabled': {
                    color: 'text.secondary',
                  },
                }}
              >
                {counters.savings_count}
              </Button>
            ) : null}
          </ButtonGroup>
        }
      />

      <CardActions disableSpacing>
        <Button
          href={'https://dev.to/' + path}
          size="small"
          startIcon={<ArticleIcon />}
        >
          Read
        </Button>
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            marginLeft: 'auto',
            marginRight: '12px',
          }}
        >
          {info.reading_time_minutes.toString()} min read / {info.readable_publish_date}
        </Typography>
      </CardActions>
    </Card>
  );
}

function Articles() {
  return (
    <Box>
      <DevToArticle
        path="alinsky/automation-scheduling-python-programs-pushing-notifications-executing-sqls-etc-4ob0"
        medium="@alinsky/automation-scheduling-python-programs-pushing-notifications-executing-sqls-etc-c0c06bd3a4d7"
        hackernoon="mastering-python-script-scheduling-pitfalls-and-solutions"
      />
    </Box>
  );
}

export default Articles;
