import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import useStyles from '../style';

function Footer() {
  const classes = useStyles();
  return (
    <Paper className={classes.footer}>
      <Box className={classes.footerContent}>
        <Typography variant="overline">
          Copyright © 2023, Vladimir Alinsky
        </Typography>
      </Box>
    </Paper>
  );
}

export default Footer;
