import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const maxWidth = '620px';

const useStyles = makeStyles({
  root: {
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '32px 0px 96px', // 64 + 32 = 96
    display: 'flex',
    maxWidth: maxWidth,
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  footer: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    padding: '16px 16px',
  },
  footerContent: {
    maxWidth: maxWidth,
    marginRight: 'auto',
    marginLeft: 'auto',
    textAlign: 'center',
  },
  block: {
    paddingTop: '16px',
  },
  '@keyframes pulseAnimation': {
    '0%': {
      boxShadow: '0 0 0 0px rgba(255, 255, 255, 0.2)',
    },
    '50%': {
      boxShadow: '0 0 0 0px rgba(255, 255, 255, 0.2)',
    },
    '100%': {
      boxShadow: '0 0 0 8px rgba(255, 255, 255, 0)',
    },
  },
  pulse: {
    animation: '$pulseAnimation 3s infinite',
  },
});

export default useStyles;
