import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Card, CardHeader } from '@mui/material';
import React from 'react';

function Contacts() {
  return (
    <Card sx={{ marginBottom: '16px' }}>
      <CardHeader
        subheader="Contacts"
        action={
          <>
            <Button
              startIcon={<TelegramIcon />}
              href="https://t.me/sky_alin"
              size="small"
              sx={{ textAlign: 'right', margin: '0px 8px' }}
            >
              Telegram
            </Button>
            <Button
              startIcon={<AlternateEmailIcon />}
              href="mailto:vladimir@alinsky.tech"
              sx={{ textAlign: 'right', margin: '0px 8px' }}
            >
              Email
            </Button>
          </>
        }
      />
    </Card>
  );
}

export default Contacts;
