import { Box } from '@mui/material';
import { Project } from './index';
import React from 'react';

function OpenSourceProjects() {
  return (
    <Box>
      <Project title="Regta Scheduler" github="SKY-ALIN/regta" />
      <Project title="telegram-text" github="SKY-ALIN/telegram-text" />
      <Project title="space-drive-game" github="SKY-ALIN/space-drive-game" />
      <Project title="regta-period" github="SKY-ALIN/regta-period" />
      <Project title="bevy-space-physics" github="SKY-ALIN/bevy-space-physics" />
      <Project title="bioforma" github="SKY-ALIN/bioforma" />
    </Box>
  );
}

export default OpenSourceProjects;
