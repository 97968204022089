import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import useStyles from '../style';

function MobileMenuButton({
  text,
  onClick = () => {},
  up = false,
  down = false,
  showEffect = false,
}: {
  text: string;
  onClick?: () => void;
  up?: boolean;
  down?: boolean;
  showEffect?: boolean;
}) {
  const classes = useStyles();
  const padding = up ? '12px 4px' : '20px 4px 4px';
  return (
    <Card
      sx={{ marginBottom: '16px' }}
      className={showEffect ? classes.pulse : undefined}
    >
      <CardActionArea onClick={onClick}>
        <CardContent sx={{ textAlign: 'center', padding: padding }}>
          {up ? (
            <Typography color="text.secondary">
              <ExpandLessIcon />
            </Typography>
          ) : null}
          <Typography variant="body1" color="text.secondary">
            {text}
          </Typography>
          {down ? (
            <Typography color="text.secondary">
              <ExpandMoreIcon />
            </Typography>
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default MobileMenuButton;
