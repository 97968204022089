import React from 'react';
import { Button } from '@mui/material';

export function MediaButton({
  text,
  icon,
  ...props
}: {
  text: string;
  icon: JSX.Element;
  href?: string;
  onClick?: () => void;
}) {
  return (
    <Button
      variant="text"
      size="small"
      sx={{ margin: '8px' }}
      startIcon={icon}
      {...props}
    >
      {text}
    </Button>
  );
}

export default MediaButton;
