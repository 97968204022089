import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import React from 'react';

function DesktopMenuButton({
  text,
  icon,
  onClick = () => {},
  active = false,
}: {
  text: string;
  icon: JSX.Element;
  onClick?: () => void;
  active?: boolean;
}) {
  return (
    <Card elevation={active ? 10 : 1} sx={{ marginBottom: '16px' }}>
      <CardActionArea onClick={onClick}>
        <CardContent sx={{ textAlign: 'center', padding: '12px 4px' }}>
          <Typography color="text.secondary">{icon}</Typography>
          <Typography variant="caption" color="text.secondary">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default DesktopMenuButton;
