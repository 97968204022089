import React from 'react';
import { Grid, Collapse } from '@mui/material';

import GitHubIcon from '@mui/icons-material/GitHub';
import PersonIcon from '@mui/icons-material/Person';
import NewspaperIcon from '@mui/icons-material/Newspaper';

import {
  Articles,
  DesktopMenuButton,
  Contacts,
  PersonalCard,
  OpenSourceProjects,
  Footer,
} from './components';
import useStyles from './style';

function DesktopApp() {
  const classes = useStyles();
  const [page, setPage] = React.useState('about');

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <DesktopMenuButton
            text="About"
            icon={<PersonIcon />}
            onClick={() => setPage('about')}
            active={page === 'about'}
          />
          <DesktopMenuButton
            text="Open Source"
            icon={<GitHubIcon />}
            onClick={() => setPage('open_source')}
            active={page === 'open_source'}
          />
          <DesktopMenuButton
            text="Articles"
            icon={<NewspaperIcon />}
            onClick={() => setPage('articles')}
            active={page === 'articles'}
          />
        </Grid>

        <Grid item xs={10}>
          <Collapse in={page === 'about'}>
            <PersonalCard />
          </Collapse>
          <Collapse in={page === 'open_source'}>
            <OpenSourceProjects />
          </Collapse>
          <Collapse in={page === 'articles'}>
            <Articles />
          </Collapse>
          <Contacts />
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}

export default DesktopApp;
