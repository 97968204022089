import React from 'react';
import { Container, Collapse } from '@mui/material';

import {
  Footer,
  PersonalCard,
  Contacts,
  MobileMenuButton,
  OpenSourceProjects,
  Articles,
} from './components';
import useStyles from './style';

function MobileApp() {
  const classes = useStyles();

  const [page, setPage] = React.useState('about');
  const [showEffect, setShowEffect] = React.useState(true);

  return (
    <div className={classes.root}>
      <Container>
        <Collapse in={page === 'about'}>
          <PersonalCard />
          <Contacts />
          <MobileMenuButton
            down
            text="Open Source Projects"
            showEffect={showEffect}
            onClick={() => {
              setPage('open_source');
              setShowEffect(false);
            }}
          />
        </Collapse>

        <Collapse in={page === 'open_source'}>
          <MobileMenuButton up text="About" onClick={() => setPage('about')} />
          <OpenSourceProjects />
          <Contacts />
          <MobileMenuButton
            down
            text="Articles"
            onClick={() => setPage('articles')}
          />
        </Collapse>

        <Collapse in={page === 'articles'}>
          <MobileMenuButton
            up
            text="Open Source Projects"
            onClick={() => setPage('open_source')}
          />
          <Articles />
          <Contacts />
        </Collapse>
      </Container>
      <Footer />
    </div>
  );
}

export default MobileApp;
