import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';

import ForkRightIcon from '@mui/icons-material/ForkRight';
import GitHubIcon from '@mui/icons-material/GitHub';
import HomeIcon from '@mui/icons-material/Home';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import axios from 'axios';

interface ProjectInfo {
  starsCount: number;
  forksCount: number;
  description: string;
  homepage: string;
  language: string;
  license: string;
}

function Project({ title, github }: { title: string; github: string }) {
  const [info, setInfo] = useState<ProjectInfo>({
    starsCount: 0,
    forksCount: 0,
    description: 'Loading...',
    homepage: '',
    language: '',
    license: '',
  });

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 768;

  const cardActionsDirection = isMobile ? 'column-reverse' : 'row';
  const licenseAlignSelf = isMobile ? 'start' : 'center';
  const licenseMarginBottom = isMobile ? '12px' : '0px';

  useEffect(() => {
    axios
      .get(`https://api.github.com/repos/${github}`)
      .then(({ data }) => {
        setInfo({
          starsCount: data.stargazers_count,
          forksCount: data.forks_count,
          description: data.description,
          homepage: data.homepage,
          language: data.language,
          license: data.license ? data.license.spdx_id : null,
        });
      })
      .catch(() => {});
  }, [github]);

  return (
    <Card sx={{ marginBottom: '16px' }}>
      <CardHeader
        sx={{ paddingBottom: '0px' }}
        title={
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
        }
        action={
          <ButtonGroup sx={{ marginLeft: 'auto', marginRight: '12px' }}>
            {info.starsCount > 0 ? (
              <Button
                disabled
                variant="text"
                color="inherit"
                size="large"
                startIcon={<StarOutlineIcon />}
                sx={{
                  paddingRight: '0px',
                  paddingLeft: '0px',
                  '& .MuiButton-startIcon': {
                    margin: '0px',
                  },
                  ':disabled': {
                    color: 'text.secondary',
                  },
                }}
              >
                {info.starsCount.toString()}
              </Button>
            ) : null}

            {info.forksCount > 0 ? (
              <Button
                disabled
                variant="text"
                color="inherit"
                size="large"
                startIcon={<ForkRightIcon />}
                sx={{
                  paddingRight: '0px',
                  paddingLeft: '0px',
                  '& .MuiButton-startIcon': {
                    margin: '0px',
                  },
                  ':disabled': {
                    color: 'text.secondary',
                  },
                }}
              >
                {info.forksCount.toString()}
              </Button>
            ) : null}
          </ButtonGroup>
        }
      ></CardHeader>

      <CardContent sx={{ paddingTop: '0px', paddingBottom: '8px' }}>
        <Typography variant="body2" color="text.secondary">
          {info.description}
        </Typography>
      </CardContent>

      <CardActions
        sx={{
          display: 'flex',
          flexDirection: cardActionsDirection,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flex: 1,
            alignItems: 'center',
            alignSelf: 'start',
          }}
        >
          {info.homepage !== '' ? (
            <Button href={info.homepage} size="small" startIcon={<HomeIcon />}>
              Homepage
            </Button>
          ) : null}

          <Button
            href={'https://github.com/' + github}
            size="small"
            startIcon={<GitHubIcon />}
          >
            GitHub
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flex: 1,
            justifyContent: 'end',
            alignSelf: licenseAlignSelf,
            marginRight: '16px',
            marginBottom: licenseMarginBottom,
          }}
        >
          <Typography variant="body2" sx={{color: 'text.secondary'}}>
            {info.language} language, {info.license ? info.license : 'no'} license
          </Typography>
        </Box>
      </CardActions>
    </Card>
  );
}

export default Project;
